import React,{ useEffect, useState } from "react";
import { pythonURL } from "../ClientComponenet/variables";
import { useCookies } from "react-cookie";


const MenuButton = ({data,setIndex,i}) =>
{
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return(<li className="mb-4"><a className="has-background-black-ter has-text-white" onClick={() => setIndex(i)}>{data.special_nickname}</a></li>)
}
const OutputButton = ({data,setIndex,i}) =>
{
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return(<button className="button has-background-black-ter has-text-white">{data.name}</button>)
}

const OutputsComp = ({currtab,index}) =>
{

    const [outputsInfo,setOutputsInfo] = useState();
    const [tabOutputsMenu,setTabOutputsMenu] = useState();
    const [outputTags,setOutputTags] = useState();
    const [tabIndex,setIndex] = useState();
    const [cookies, , ] = useCookies(['redirection',"jwt"]);

    useEffect(() =>
    {   
        const getOutputPack = async () =>
        {
            await fetch (pythonURL+"/GetSpecialOutputData/",{method: "get",headers: {"authorization" : cookies.jwt}})
            .then(async (data) => 
            {
                data = await data.json()
                console.log(data)
                data.data = data.data.sort((a,b) => a.index-b.index)
                setOutputsInfo(data.data);
                let outputTagtmp = [];
                for(let i=0;i<Object.keys(data.data).length;i++)
                {
                    outputTagtmp.push([]);
                    let packs = Object.keys(data.data[i].outputs);
                    for(let j=0;j<packs.length;j++)
                    {
                        outputTagtmp[i].push(data.data[i].outputs[packs[j]].sort((a,b) => a.index-b.index).map((val,i) => <React.Fragment key={i}><OutputButton pack={packs[j]} data={val} i={i} setIndex={setIndex}/></React.Fragment>))
                    }
                }
                setOutputTags(outputTagtmp)
                setTabOutputsMenu(data.data.map((val,i) => <React.Fragment key={i}><MenuButton data={val} i={i} setIndex={setIndex}/></React.Fragment>));
                
            })
            .catch(error => {console.log("error " + error);});
        }
        getOutputPack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
    <>
        {currtab === index ? 
        <div className="columns" style={{"zoom":"125%","height": "90%"}}>
            <div className="column is-4">
                <h1 className="title has-text-white-bis"><center>Tabs</center></h1>
                <ul className="menu-list">
                    {tabOutputsMenu}
                </ul>
            </div>
            <div><hr className='hrdesign'/></div>
            <div className="column">
                <h1 className="title has-text-white-bis"><center>Information</center></h1>
                {
                    tabIndex !== undefined ?
                        <>
                            <div className="columns is-centered">
                                <div className="column ">
                                    <center>
                                        <div className="mb-4">
                                            <p className="has-text-warning ">Nickname</p>
                                            <p className="has-text-white">{outputsInfo[tabIndex].special_nickname}</p>
                                        </div>
                                        <div>
                                            <p className="has-text-warning ">Is Cash Date</p>
                                            <p className="has-text-white">{outputsInfo[tabIndex].is_cashdate+""}</p>
                                        </div>
                                    </center>
                                </div>
                                <div className="column">
                                    <center>

                                        <div className="mb-4">
                                            <p className="has-text-warning ">Index</p>
                                            <p className="has-text-white">{outputsInfo[tabIndex].index}</p>
                                        </div>
                                        <div>
                                            <p className="has-text-warning ">Folder path</p>
                                            <p className="has-text-white">{outputsInfo[tabIndex].special_folder}</p>
                                        </div>
                                    </center>
                                </div>
                            </div>
                            <h1 className="subtitle has-text-warning mb-2"><center>Outputs</center></h1>
                            <div className="buttons">
                                {outputTags[tabIndex]}
                            </div>
                        </>
                    : <></>
                }
            </div>
        </div>
         :
        <></>}
    </>);
}

export default OutputsComp;